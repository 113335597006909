import { BsChevronCompactRight, BsFillTrash3Fill, BsThreeDotsVertical } from "react-icons/bs"
import './CreateChecklist.scss';
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import actionbtn from "../../../assets/addaction-btn.png";
import { GetTemplateApi, GetTemplateByIdApi } from "../../../services/TemplateManagement.service";
import toast from "react-hot-toast";
import { GetAllPropertyApi } from "../../../services/PropertyManagement.service";
import { GetUsersApi } from "../../../services/UserManagement.service";
import { CreateChecklistApi } from "../../../services/ChecklistManagement.service";
import { Navigate, useNavigate } from "react-router-dom";
import NotificationModal from "../../../components/ConfirmationModal/ConfirmationModal";

interface Item {
    id: string;
    content: string;
    inputValue: string;
    category: string;
    selectValue: string;
    toggleValue: boolean;
  }
  
  const getItems = (count: number): Item[] => 
    Array.from({ length: count }, (_, k) => ({
      id: `item-${k}`,
      content: `item ${k}`,
      inputValue: '',
      category: '',
      selectValue: '',
      toggleValue: true,
    }));
  
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid: number = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? 'lightgreen' : 'transparent',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? 'lightblue' : '#F6F6F6',
  padding: grid,
  width: "100%",
});
export const CreateChecklist=()=>{
  const navigate = useNavigate();
    const [isDisabled, setDisabled] = useState(false);
    const [isSection, setSection] = useState(false);
    const [items, setItems] = useState<Item[]>(getItems(1));

    const [templateData, setTemplateData] = useState<any>();
    const [propertyData, setPropertyData] = useState<any>();
    const [userData, setUserData] = useState<any>();

    const [template, setTemplate] = useState<any>('');
    const [property, setProperty] = useState<any>('');
    const [user, setUser] = useState<any>('');
    const [checklistDate, setChecklistDate] = useState<any>('');
    const [checklistName, setChecklistName] = useState<any>('');
    const [templateValue, setTemplateValue] = useState<any>();
    const [discardShow, setDiscardModalShow] = useState(false);
    const toggleDiscardModal = () => setDiscardModalShow(!discardShow);


    useEffect(()=>{
      getTemplates();
      GetProperties();

},[])


useEffect(()=>{
GetUsers()

},[property])
    const getTemplates= async ()=>{
      const template_data = await GetTemplateApi();

      if(template_data){
        if(template_data.status==100){
          setTemplateData(template_data)
        }else{
          toast.error("Error retrieving templates")
        }
      }
    }



    const GetProperties= async()=>{
      const property_data = await GetAllPropertyApi();
      console.log(property_data)

      if(property_data.status==100){
          setPropertyData(property_data.data)
          
      }else{
        toast.error("Error retrieving properties")
      }
      
  }


  const GetUsers= async()=>{
    let keyword="property_id="+property
      const users_data = await GetUsersApi(keyword);
      console.log(users_data)
      let data_array:any=[]
      if(users_data.status==100){
          // for(let i=0;i<users_data.data.length;i++){
        
          //  if(!users_data.data[i].is_super_admin){
          //   let data_json={
          //     id:users_data.data[i].id,
          //     name:users_data.data[i].first_name + " "+users_data.data[i].last_name,
          //     role:users_data.data[i].is_admin && users_data.data[i].property_name?"Admin":users_data.data[i].is_user?"User":"Default Admin",
          // }
          // data_array= [data_json,...data_array]
          //  }  

          // }
          console.log("vadad",data_array)
           setUserData(users_data.data)
          
      }
      
  }


      
      const handleKeyPress = (event:any) => {
        if (event.key === 'Enter') {
          addItem();
        }
      }
      
    const onDragEnd = useCallback((result: DropResult) => {
        console.log("hi",result)
      if (!result.destination) {
        return;
      }
  
      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );
  
      setItems(newItems);
    }, [items]);


    const validateActions =()=>{
      const allItemsValid = items.every(item => item.inputValue !== '' && (((templateValue && templateValue.priority_is_active==1) && item.selectValue !== '')||templateValue.priority_is_active==0));
      return allItemsValid
    }

    const addItem = () => {
      // Check if all items in the list have non-empty inputValue and selectValue
      const allItemsValid = items.every(item => item.inputValue !== '' && item.selectValue !== '');
    
      if (validateActions()) {
        // If all items are valid, add a new item
        const newItem = {
          id: `item-${items.length}`, // Ensures a unique ID
          content: `item ${items.length}`,
          inputValue: '',
          category: '',
          selectValue: '',
          toggleValue: true,
        };
        setItems([...items, newItem]);
      } else {
        // If any item is invalid, you can handle it here (e.g., show an error message)
        toast.error("Action item and priority should be filled before adding a new line")
      }
    };
    const updateItem = (index: number, field: string, value: any) => {
        const newItems = [...items];
        newItems[index] = { ...newItems[index], [field]: value };
        setItems(newItems);
      };


      const removeItem = (index:any) => {
        setItems(prevItems => prevItems.filter((_, i) => i !== index));
      };
    

      const discardForm=()=>{
        setItems([])
        setChecklistDate('')
        setChecklistName('')
        setProperty('')
        setTemplate('')
        setUser('')
        setDiscardModalShow(false)
        navigate('/checklist-management')
      }
      const submitData = async() => {
        console.log(" items.length", items.length)
        if(!validateActions() || items.length==0){
          return toast.error("At least 1 action item should be filled")
        }

        if(!template){
          return toast.error("Template is mandatory")
        }

        if(!checklistDate){
          return toast.error("Due date is mandatory")
        }

        if(!property){
          return toast.error("Property is mandatory")
        }

        
        if(!user){
          return toast.error("User assignee is mandatory")
        }

        if(!checklistName){
          return toast.error("Checklist name is mandatory")
        }


        const valuesArray = items.map(item => ({
          action_item: item.inputValue,
          category:item.category,
          priority: item.selectValue,
          is_active:item.toggleValue?1:0
        }));
        
        
        let data={
          name:checklistName,
          template_id:template,
          property_id:property,
          assignee_id:user,
          due_date:checklistDate,
          action_items:valuesArray
        }
        console.log("data to submit",data);
        setDisabled(true)
        const create_response = await CreateChecklistApi(data)
        if(create_response){
            setDisabled(false)
            if(create_response.status==100){
                toast.success("Successfully created checklist")
                setItems([])
                setChecklistDate('')
                setChecklistName('')
                setProperty('')
                setTemplate('')
                setUser('')
            }else{
                toast.error("Error creating checklist: "+create_response.message)
            }
        }
      };
    
      const onTemplateChange=async(id:any)=>{
        setTemplate(id)
        const getTemplateData = await GetTemplateByIdApi(id)

        if(getTemplateData.status==100){
          setTemplateValue(getTemplateData.data)
          console.log("aafafafac",getTemplateData)
        }
      }

    return(
        <div className='main-content  fade-in'>
          <div className="d-flex">
            <p className="breadcrumb pe-2">Management</p>
            <BsChevronCompactRight/>
            <p onClick={()=>navigate('/checklist-management')} className="breadcrumb ps-2 pe-2">Checklist Management</p>
            <BsChevronCompactRight/>
            <p className="breadcrumb ps-2">Create Checklist</p>
          </div>

          <div>
          <div className="col-6 col-md-3 col-lg-3 pb-1 pt-3">
              <label className="checklist-labels">Select Template <span style={{color:'red'}}>*</span></label>
              <select onChange={(e)=>{onTemplateChange(e.target.value);}} value={template} className=" mt-1 checklist-select">
                <option value="">Template Name</option>
                {templateData && templateData.data.map((value:any,index:number)=>(
                  <option value={value.id}>{value.name}</option>
                ))}
              </select>
          </div>


          <div className="col-6 col-md-3 col-lg-3 pb-1 pt-3">
              <label className="checklist-labels">Select Due date <span style={{color:'red'}}>*</span></label>
        <input onChange={(e)=>setChecklistDate(e.target.value)} value={checklistDate} className="checklist-input" type="date"/>
          </div>



          <div className="row">
          <div className="col-6 col-md-6 col-lg-6 pb-4 pt-1">
              <label className="checklist-labels">Select Property <span style={{color:'red'}}>*</span></label>
              <select  onChange={(e)=>setProperty(e.target.value)} value={property} className=" mt-1 checklist-select">
                <option value="">Property Name</option>
                {propertyData && propertyData.map((value:any,index:number)=>(
                  <option value={value.id}>{value.name}</option>
                ))}
              </select>
          </div>

          <div className="col-6 col-md-6 col-lg-6 pb-4 pt-1">
              <label className="checklist-labels">Assign To <span style={{color:'red'}}>*</span></label>
              <select disabled={property==""} onChange={(e)=>setUser(e.target.value)} value={user} className=" mt-1 checklist-select">
                <option value="">Select User</option>
                {userData && userData.map((value:any,index:number)=>(
                  !value.is_super_admin && value.is_verified && value.is_active? <option value={value.id}>{value.first_name} {value.last_name}  {value.is_admin?'(Admin)':'(User)'}</option>:null                ))}
              </select>
          </div>

          </div>
          </div>


          <div className="pb-3">
    <label className="checklist-labels">Checklist Name <span style={{color:'red'}}>*</span></label>
    <input onChange={(e)=>setChecklistName(e.target.value)} value={checklistName} className=" mt-1 checklist-input" placeholder="Enter the template name"/>
</div>



{templateValue &&<DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
               {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                   
                      <div className="row justify-content-around">
              {templateValue && templateValue.action_item_is_active &&          <div className="col-12 col-md-5 col-lg-5 d-flex">
                            <div className="d-flex justify-content-center align-items-center pe-2">
                        <BsThreeDotsVertical />
                        </div>
                          <input 
                            placeholder="Enter the action item" 
                            className="checklist-input me-1" 
                            value={item.inputValue} 
                            onChange={(e) => updateItem(index, 'inputValue', e.target.value)}
                          />
                        </div>}

              { templateValue && templateValue.category_is_active &&          <div  className="col-6 col-md-2 col-lg-2 mt-2 mt-md-0 mt-lg-0">
                        <input 
                            placeholder="Category" 
                            className="checklist-input me-1" 
                            value={item.category} 
                            onChange={(e) => updateItem(index, 'category', e.target.value)}
                          />
                        </div>}
             {templateValue && templateValue.priority_is_active==1 &&            <div className="col-6 col-md-2 col-lg-2 mt-2 mt-md-0 mt-lg-0">
                          <select 
                            className="checklist-select" 
                            value={item.selectValue} 
                            onChange={(e) => updateItem(index, 'selectValue', e.target.value)} onKeyPress={handleKeyPress}
                          >
                            <option value="">Select</option>
                            <option value="high">High priority</option>
                            <option value="medium">Medium priority</option>
                            <option value="low">Low priority</option>
                          </select>
                        </div>}
                        <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                              checked={item.toggleValue} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              id={`flexSwitchCheckDefault-${index}`} 
                              onChange={(e) => updateItem(index, 'toggleValue', e.target.checked)}
                            />
                          </div>
                         
                        </div>

                        <div className="col-1 d-flex align-items-center" style={{cursor:'pointer'}}>
                        {items.length>1 &&  <span onClick={()=>removeItem(index)}><BsFillTrash3Fill/></span>}
                        </div>
                      </div>

            

                      
                    </div>
                  )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
}

    <div className="pt-3">
      <button onClick={addItem} type="button" className="create-checklist-btn" > <span className='pe-2'><img src={actionbtn} /></span>
      Add New Action      </button>
        </div>


    <div className="pt-3 pb-4 d-flex justify-content-end">
    <button onClick={()=>toggleDiscardModal()} className="discard-btn me-3">Discard</button>
    <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={submitData}
        is_loading={isDisabled}
        classname="editsave-btn"
      />
</div>


<NotificationModal
        show={discardShow}
        toggleModal={toggleDiscardModal}
        title="Create User Account"
      >
       <p className='delete-confirmation'> Are you sure you want to discard all changes ?</p>

        <div className='d-flex justify-content-center'>
        <button onClick={()=>discardForm()} className="delete-btn-confirm me-3 mt-3">Yes</button>
        <button onClick={()=>toggleDiscardModal()} className="no-btn-confirm me-3 mt-3">No</button>
        </div>
      </NotificationModal>
      
        </div>
    )
}

