import React, { ReactNode } from 'react';
import './App.scss';
import { BrowserRouter , Routes , Route, Navigate   } from 'react-router-dom';
import Login from './pages/Authentication/Login/Login';
import ForgotPassword from './pages/Authentication/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/Authentication/ResetPassword/ResetPassword';
import Dashboard from './pages/Dashboard/Dashboard';
import toast, { Toaster } from 'react-hot-toast';
import { Layout } from './layouts/MainLayout/MainLayout';
import UserManagement from './pages/UserManagement/UserManagement';
import PropertyManagement from './pages/PropertyManagement/PropertyManagement';
import { PasswordManagement } from './pages/PasswordManagement/PasswordManagement';
import Settings from './pages/Settings/Settings';
import ChecklistManagement from './pages/ChecklistManagement/ChecklistManagement';
import { CreateChecklist } from './pages/ChecklistManagement/CreateChecklist/CreateChecklist';
import { DataProvider } from './context/DataState';
import ViewChecklist from './pages/ChecklistManagement/ViewChecklist/ViewChecklist';
import TemplateManagement from './pages/TemplateManagement/TemplateManagement';
import CreateTemplate from './pages/TemplateManagement/CreateTemplate/CreateTemplate';
import ViewTemplate from './pages/TemplateManagement/ViewTemplate/ViewTemplate';
import PrintChecklist from './pages/ChecklistManagement/PrintChecklist/PrintChecklist';
import { Support } from './pages/Support/Support';
import { Home } from './pages/Home/Home';
import { Company } from './pages/Company/Company';
import { ViewCompany } from './pages/Company/ViewCompany/ViewCompany';


type ProtectedRouteProps = {
  children: ReactNode;
};


// Mock function to check if the user is authenticated
const isAuthenticated = () => {
  // You should replace this with a real check
  // It might look for a token in localStorage, or a global state
  return localStorage.getItem("access_token") || sessionStorage.getItem("access_token");
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  if (!isAuthenticated()) {
    // User is not authenticated, redirect to login page
    return <Navigate to="/login" />;
  }
  return  <Layout>{children}</Layout>;
};

function App() {
  return (
    <div className='app-container'>
      
    <BrowserRouter>
    <DataProvider>
    <Routes>
    <Route path="/login" element={<Login/>}  /> 
    <Route path="/forgot-password" element={<ForgotPassword/>}  /> 
    <Route path="/reset-password" element={<ResetPassword/>}  /> 
 
    <Route
            path="/"
            element={
              <ProtectedRoute>
                 

                <Dashboard />
             
              </ProtectedRoute>
            }
          /> 


<Route
            path="/user-management"
            element={
              <ProtectedRoute>
              

                <UserManagement />
           
              </ProtectedRoute>
            }
          /> 
    
    <Route
            path="/property-management"
            element={
              <ProtectedRoute>
              

                <PropertyManagement />
           
              </ProtectedRoute>
            }
          /> 


<Route
            path="/password-management"
            element={
              <ProtectedRoute>
              

                <PasswordManagement />
           
              </ProtectedRoute>
            }
          /> 


 
<Route
            path="/template-management"
            element={
              <ProtectedRoute>
              

                <TemplateManagement />
           
              </ProtectedRoute>
            }
          />          


<Route
            path="/create-template/:id"
            element={
              <ProtectedRoute>
              

                <CreateTemplate />
           
              </ProtectedRoute>
            }
          />       

<Route
            path="/view-template/:id"
            element={
              <ProtectedRoute>
              

                <ViewTemplate />
           
              </ProtectedRoute>
            }
          />                 


<Route
            path="/checklist-management"
            element={
              <ProtectedRoute>
              

                <ChecklistManagement />
           
              </ProtectedRoute>
            }
          /> 


<Route
            path="/checklist-management/view/:id"
            element={
              <ProtectedRoute>
              

                <PrintChecklist />
           
              </ProtectedRoute>
            }
          /> 


<Route
            path="/checklist/:id"
            element={
              <ProtectedRoute>
              

                <ViewChecklist />
           
              </ProtectedRoute>
            }
          /> 

<Route
            path="/checklist-management/create-checklist"
            element={
              <ProtectedRoute>
              

                <CreateChecklist />
           
              </ProtectedRoute>
            }
          /> 


<Route
            path="/settings"
            element={
              <ProtectedRoute>
              

                <Settings />
           
              </ProtectedRoute>
            }
          /> 


<Route
            path="/support"
            element={
              <ProtectedRoute>
              

                <Support />
           
              </ProtectedRoute>
            }
          /> 

<Route
            path="/company"
            element={
              <ProtectedRoute>
              

                <Company />
           
              </ProtectedRoute>
            }
          /> 


<Route
            path="/view-company/:id"
            element={
              <ProtectedRoute>
              

                <ViewCompany />
           
              </ProtectedRoute>
            }
          /> 

<Route
            path="/dashboard"
            element={
              <ProtectedRoute>
              

                <Home />
           
              </ProtectedRoute>
            }
          /> 
    
    </Routes>
    </DataProvider>
  </BrowserRouter>
  <Toaster />
  <footer className='text-center filter-label'>v0.0.2 - 18/09/2024</footer>
  </div>
  );
}

export default App;